import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Image from "../components/image"

function InstructionPage() {
  return (
    <Layout>
      <SEO
        keywords={[`Montessori`, `montessori`, `Montessori Material`]}
        title="How to use Classified Cards Editor"
      />
      <main className="mt-10 mb-10 mx-auto max-w-7xl sm:mt-12 sm:mb-12 md:mt-16 md:mb-16 lg:mt-20 lg:mb-20 xl:mt-28 xl:mb-28">
        <div className="sm:text-left lg:text-left">
          <h1 className="text-2xl tracking-tight font-extrabold text-gray-900 sm:text-3xl md:text-4xl mb-5">
            <span className="block xl:inline">How to use Classified Cards Editor</span>
          </h1>

          {[
              {
                title: `1. Prepare images`,
                text: `You can use JPEG or PNG images. 
                      I recommend square images (equal width and height). `,
                image: "instructions1.png",
              },
              {
                title: ``,
                text: `How images are processed:
                  In the case of rectangular images, the image will be cropped as a square to fit the shorter width or height.`,
                image: "instructions2.png",
              },
              {
                title: `2. Select the language`,
                text: `Select the language from the drop-down menu. 
                    Here the image shows a screen where you can make cards in English and Japanese at the same time. `,
                image: "instructions3.png",
              },
              {
                title: ` `,
                text: `This image shows a screen where you can make cards in English. `,
                image: "instructions4.png",
              },
              {
                title: ` `,
                text: `This image shows a screen where you can make cards in Japanese. `,
                image: "instructions5.png",
              },
              {
                title: `3. Enter data`,
                text: `Enter names and select images, then click the Update PDF button.`,
                image: "instructions6.png",
              },
              {
                title: `4. Select English Fonts`,
                text: `You can select "Montessoriscript" or "AndikaNewBasic".`,
                image: "instructions7.png",
              },
              {
                title: `5. Select the Style`,
                text: `You can select the style of frames from "Rectangle" or "Rounded Rectangle".
                      This image shows "Rectangle" style.`,
                image: "instructions8.png",
              },
              {
                title: ` `,
                text: `This image shows "Rounded Rectangle" style.`,
                image: "instructions9.png",
              },
              {
                title: `6. Select the Frame color (English)`,
                text: `You can select the color of the frame.This color helps to classify categories. If you choose white, you can clear the color.`,
                image: "instructions10.png",
              },
              {
                title: `7. Select the Frame color (Japanese)`,
                text: `You can choose different colors in Japanese and English. `,
                image: "instructions11.png",
              },
              {
                title: `8. Click the download pdf button.`,
                text: `"document.pdf" will be downloaded.`,
                image: "instructions12.png",
              },
              {
                title: `Give me a coffee button`,
                text: `I am developing Montessori ICU in my spare time. If you use these tools and support my development, please feel free to buy me a coffee here. ☕️`,
                image: "instructions13.png",
              },
              {
                title: `9. Print out`,
                text: `Print it out on A4 size paper. When you choose double-sided printing, you can make cards with English on the front and Japanese on the back. It is important to note that in order to use these cards, the child needs to be able to distinguish between Japanese and English when self-correcting.`,
                image: "instructions14.png",
              },
              {
                title: `10. Cut out`,
                text: `Cut along the lines. It is recommended to laminate.`,
                image: "instructions15.png",
              },
              {
                title: ``,
                text: `When you chose double-sided printing, you will have double-sided cards.`,
                image: "instructions16.png",
              },
            ].map((link) => (
              <div
                key={link.title}
              >
              <figure className="md:flex bg-gray-100 rounded-xl p-8 mb-5">
                <Image    
                  className="flex-none object-none object-left-top w-2/3 h-auto mr-5 mb-5 lg:md-0 xl:md-0 2xl:md-0" 
                  width="600" 
                  assetUrl= {link.image}
                  alt="instructions1"
                />
                <div className="text-left">
                  <h2 className="font-bold">
                  {link.title}
                  </h2>
                  <p className="text-gray-600">
                  {link.text}
                  </p>
                </div>
              </figure>
              </div>
            ))
          }
        </div>
      </main>
    </Layout>
  );
}



export default InstructionPage;
